import ipRegex from 'ip-regex'

export const cleanUrl = (url: string) => {
  if (url.startsWith('http')) {
    url = url.replace('http://', '').replace('https://', '')
  }
  if (url.startsWith('www.')) {
    url = url.replace('www.', '')
  }
  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }
  return url
}

export function replaceHttpsWithHttp(url: string) {
  return url.replace('https://', 'http://')
}

export const prepareUrl = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url
  }
  return url
}

export function getSocialMediaDataUrl(platformName: string, slug: string): string {
  switch (platformName) {
    case 'tiktok':
      return `tiktok.com/@${slug}`
    case 'medium':
      return `medium.com/@${slug}`
    case 'tumblr':
      return `${slug}.tumblr.com/`
    case 'youtube':
      return `youtube.com/@${slug}`
    case 'pinterest':
      return `pinterest.com/${slug}/`
    case 'spotify':
      return `open.spotify.com/user/${slug}`
    case 'telegram':
      return `t.me/${slug}`
    case 'twitch':
      return `twitch.tv/${slug}`
    case 'instagram':
      return `instagram.com/${slug}/`
    case 'linktree':
      return `linktr.ee/${slug}`
    case 'reddit':
      return `reddit.com/user/${slug}/`
    case 'facebook':
      return `facebook.com/${slug}`
    case 'weibo':
      return `weibo.com/u/${slug}` // Might require an ID
    case 'linkedin':
      return `linkedin.com/in/${slug}/`
    case 'twitter':
      return `twitter.com/${slug}`
    case 'github':
      return `github.com/${slug}`
    case 'discord':
      return `discord.com/invite/${slug}`
    case 'threads':
      return `threads.net/@${slug}`
    case 'snapchat':
      return `snapchat.com/add/${slug}`
    default:
      return ''
  }
}

// Helper function to check if a string is an IP address
function isIPAddress(part: string): boolean {
  return ipRegex({ exact: true }).test(part) || ipRegex.v6({ exact: true }).test(part)
}

// Check if a URL contains an IP address
export function containsIPAddress(url: string): boolean {
  // Remove the protocol (http:// or https://)
  const withoutProtocol = url.replace(/^(https?:\/\/)/, '')

  // Split the remaining string by common delimiters except dot
  const parts = withoutProtocol.split(/[:?/]/)

  // Use the helper function to check each part for IP address format
  return parts.some(isIPAddress)
}
