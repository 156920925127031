import {
  Flex,
  HStack,
  Tab,
  TabList,
  Tabs,
  VStack,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState, useCallback } from 'react'
import OrgPicker from '../../components/internal/org_picker'
import { DOPPEL_DARK, DOPPEL_WHITE } from '../../utils/style'
import InternalDropdown from '@/components/internal/internal_dropdown'
import FeatureFlagDisplay from '@/components/internal/feature_flag_display'
import { ReportType } from '@/utils/constants'

const HIGHEST_NUMBER_TO_SHOW_IN_TAB = 1000

export type TabInfo = {
  name: string
  route: string
  isVisible: boolean
  subTabs?: TabInfo[]
  count?: number
}

export type SubTabInfo = {
  name: string
  route: string
  isVisible: boolean
}

type PageLayoutProps = {
  component: JSX.Element
  tabs?: TabInfo[]
  onTabChange?: (tab: TabInfo, index: number) => void
  onSubTabChange?: (subTab: SubTabInfo, index: number) => void
}

const getTabIndexForRoute = (tabs, route) => {
  const initTab = tabs.findIndex((tab) => route.startsWith(tab.route))
  return initTab > 0 ? initTab : 0
}

const formatCount = (count: number): string => {
  if (count >= HIGHEST_NUMBER_TO_SHOW_IN_TAB) {
    return `+${Math.floor(count / 1000)}K`
  }
  return count.toString()
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    component,
    tabs = [],
    onTabChange = () => {},
    onSubTabChange = () => {},
  } = props

  const router = useRouter()
  const [tabIndex, setTabIndex] = useState(getTabIndexForRoute(tabs, router.asPath))
  const subTabs = tabs[tabIndex]?.subTabs
  const [subTabIndex, setSubTabIndex] = useState(0)

  useEffect(() => {
    setTabIndex(getTabIndexForRoute(tabs, router.asPath))
    if (!subTabs) return
    setSubTabIndex(getTabIndexForRoute(subTabs, router.asPath))
  }, [tabs, subTabs, router.asPath])

  const tabDisplayFunc = useCallback(
    (tab) => {
      return (
        <Tab
          _selected={{ color: DOPPEL_DARK, bg: DOPPEL_WHITE }}
          fontSize={14}
          key={tab.name}
          overflow="visible"
          position="relative"
          whiteSpace="nowrap"
        >
          {tab.name}

          {tab.name === ReportType.DARK_WEB && tab.count > 0 && (
            <Badge
              alignItems="center"
              bg="red.500"
              borderRadius="full"
              color="white"
              display="flex"
              fontSize="8px"
              height="16px"
              justifyContent="center"
              lineHeight="normal"
              minWidth="16px"
              padding="0 4px"
              position="absolute"
              right="-8px"
              top="-8px"
              zIndex={1}
            >
              {formatCount(tab.count)}
            </Badge>
          )}
        </Tab>
      )
    },
    [tabs],
  )

  const bgColor = useColorModeValue(DOPPEL_WHITE, DOPPEL_DARK)

  return (
    <VStack bgColor={bgColor} width="100%">
      <VStack marginBottom={2} width="100%">
        <Flex width="100%">
          <Tabs
            index={tabIndex}
            isLazy={true}
            marginLeft={1}
            onChange={(index) => {
              setTabIndex(index)
              onTabChange(tabs[index], index)
            }}
            variant="solid-rounded"
            w="100%"
          >
            <TabList h="25px">{tabs.map(tabDisplayFunc)}</TabList>
          </Tabs>

          <HStack>
            <OrgPicker />

            <InternalDropdown />

            <FeatureFlagDisplay />
          </HStack>
        </Flex>

        {subTabs?.length && (
          <Flex justifyContent="center" width="100%">
            <Tabs
              index={subTabIndex}
              isLazy={true}
              marginLeft={1}
              onChange={(index) => {
                setSubTabIndex(index)
                onSubTabChange(subTabs[index], index)
              }}
              variant="solid-rounded"
              w="100%"
            >
              <TabList h="25px">{subTabs.map(tabDisplayFunc)}</TabList>
            </Tabs>
          </Flex>
        )}
      </VStack>

      {component}
    </VStack>
  )
}

export default PageLayout
