import { useEffect } from 'react'
import { useSendSlackMessageMutation } from '../generated/graphql'
import { getOrgData, useEmail, useIsEmployee } from './id_token_claims'
import {
  SLACK_CUSTOMER_NOTIF_ID,
  SLACK_CUSTOMER_TRIAL_NOTIF_ID,
} from '../utils/constants'
import { getDoppelLink } from '../utils/reports/report_utils'
import { OrganizationStatus } from '@/generated/enums'

// Dummy function component that sends a slack message when a user logs in
export function SlackNotificationUserOpenedHook() {
  const [email] = useEmail()
  const [orgData] = getOrgData()
  const isEmployee = useIsEmployee()
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()

  useEffect(() => {
    if (orgData && !isEmployee) {
      sendSlackMessageMutation({
        variables: {
          slack_message_input: {
            channel_id: 'C04PGDJMZ0R',
            message: `[${orgData?.name}] User ${email} opened the app`,
          },
        },
      })
    }
  }, [orgData, email])

  return <></>
}

export function sendCustomerReportedSlackMessage(
  sendSlackMessageMutation,
  email,
  orgData,
  name,
  id,
  product,
  newStatus,
) {
  const orgName = orgData?.name
  const isTrial = orgData?.organization_status === OrganizationStatus.EVALUATING

  const message = `[${orgName}] ${email} moved a threat to ${newStatus} \n * ${name} \n * Doppel Link: ${getDoppelLink(
    id,
    product,
  )}`

  sendSlackMessageMutation({
    variables: {
      slack_message_input: {
        channel_id: isTrial ? SLACK_CUSTOMER_TRIAL_NOTIF_ID : SLACK_CUSTOMER_NOTIF_ID,
        message: message,
      },
    },
  })
}

export function sendCustomerReportedBulkSlackMessage(
  sendSlackMessageMutation,
  rows: any[],
  email: string,
  orgData,
  newStatus: string,
) {
  const orgName = orgData?.name
  const isTrial = orgData?.organization_status === OrganizationStatus.EVALUATING

  const linksString = rows.reduce((acc, row) => {
    return `${acc}\n * Doppel Link: ${getDoppelLink(
      row.original.id,
      row.original.platform?.product,
    )}`
  }, '')

  const message =
    `[${orgName}] ${email} bulk moved threats to ${newStatus}` + linksString

  sendSlackMessageMutation({
    variables: {
      slack_message_input: {
        channel_id: isTrial ? SLACK_CUSTOMER_TRIAL_NOTIF_ID : SLACK_CUSTOMER_NOTIF_ID,
        message: message,
      },
    },
  })
}
