import { Link, Text } from '@chakra-ui/react'
import { prepareUrl, replaceHttpsWithHttp } from '../../utils/domain_utils'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { DOPPEL_LINK } from '../../utils/style'
import { useRef, useEffect } from 'react'
import { useGcsSignedUrl } from '@/hooks/cloud_storage'

type DoppelLinkProps = {
  name: string // text to display
  href?: string
  disableLink?: boolean // if true, never display as clickable link
  isDefanged?: boolean // if true, display as unclickable and uncopyable text
  shouldDefaultToHttp?: boolean
  [x: string]: any
  allowNonEmployees?: boolean
  shouldSign?: boolean // for gcs urls which need to be signed
}

const DoppelLink = (props: DoppelLinkProps) => {
  const { name, href, shouldSign } = props
  const { signedUrl, loading: signedUrlLoading } = useGcsSignedUrl({ url: href })

  if (!name) return null

  const linkHref = shouldSign && !signedUrlLoading ? signedUrl : href

  const content = <InternalComponent {...props} href={linkHref} />

  return content
}

function InternalComponent(props: DoppelLinkProps) {
  const {
    name,
    href,
    disableLink = false,
    isDefanged = false,
    shouldDefaultToHttp = false,
    allowNonEmployees = false,
    ...rest
  } = props
  const [isEmployeeView] = useIsEmployeeView()

  const linkRef = useRef<HTMLAnchorElement>()
  const textRef = useRef<HTMLParagraphElement>()

  const sharedProps = {
    display: 'initial',
    fontSize: 13,
    ...rest,
  }
  const wordBreak = 'break-all'

  // check if chakra is truncating text to 1 line
  useEffect(() => {
    // https://github.com/chakra-ui/chakra-ui/issues/4202#issuecomment-1167487676
    const element = textRef.current ?? linkRef.current
    if (!element) return
  }, [])

  // on double click, select the entire link
  const selectLink = (event) => {
    event.stopPropagation()

    const range = document.createRange()
    range.selectNodeContents(event.target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }

  // allow employees to link directly to website
  if (href && (allowNonEmployees || isEmployeeView) && !disableLink) {
    const reformattedUrl = prepareUrl(href)
    return (
      <Link
        color={DOPPEL_LINK}
        cursor="pointer"
        href={
          shouldDefaultToHttp ? replaceHttpsWithHttp(reformattedUrl) : reformattedUrl
        }
        isExternal
        onClick={(e) => e.stopPropagation()}
        ref={linkRef}
        wordBreak={wordBreak}
        {...sharedProps}
      >
        {name}
      </Link>
    )
  }
  // some customers request links to be completely defanged (uncopyable and unclickable)
  if (href && isDefanged) {
    return (
      <Text
        pointerEvents={'none'}
        ref={textRef}
        userSelect={'none'}
        wordBreak={wordBreak}
        {...sharedProps}
      >
        {name}
      </Text>
    )
  }
  // selectable text
  return (
    <Text
      cursor="default"
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={selectLink}
      ref={textRef}
      wordBreak={wordBreak}
      {...sharedProps}
    >
      {name}
    </Text>
  )
}

export default DoppelLink
