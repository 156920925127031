import React, { createContext, useContext, useState } from 'react'
import { Spoofing_Reports_Bool_Exp } from '../generated/graphql'
import {
  ReportFilterType,
  ReportStatus,
  ReportType,
  STANDARD_PAGE_SIZES,
  ZERO_UUID,
} from '../utils/constants'
import {
  METAVERSE_PLATFORM_SUBTYPES,
  CODE_REPOS_PLATFORM_SUBTYPES,
  categoryToReportSources,
  PLATFORM_TYPE_DISPLAY_MAP,
} from '../utils/reports/report_utils'
import { constructDarkOwlEnum } from '../utils/string_utils'
import { ReportFilter, getAllFiltersByType } from './report_table_filters'
import * as Sentry from '@sentry/nextjs'
import { AlertAgeStatus, ReportSourceCategory } from '@/generated/enums'
import moment from 'moment'
import { ALLOWED_ORG_STATUSES_FOR_UNIFIED_ALERTS } from '@/components/admin/unified_reports_table_filters'
import { getEmailWhereClause } from '@/utils/search_utils'
import { useRouter } from 'next/router'
import { parseQueryParams } from '@/utils/reports/query_params'

const SpoofReportTableFilterContext = createContext(null)

export function useSelectedPage() {
  const { page } = useContext(SpoofReportTableFilterContext)
  const [selectedPage, setSelectedPage] = page
  return [selectedPage, setSelectedPage]
}

export function useSelectedPageSize() {
  const { pageSize } = useContext(SpoofReportTableFilterContext)
  const [selectedPageSize, setSelectedPageSize] = pageSize
  return [selectedPageSize, setSelectedPageSize]
}

export function useSelectedSpoofReport() {
  const { spoofReport } = useContext(SpoofReportTableFilterContext)
  const [selectedSpoofReport, setSelectedSpoofReport] = spoofReport

  Sentry.setContext('last_selected_report', {
    report: selectedSpoofReport,
  })
  return [selectedSpoofReport, setSelectedSpoofReport]
}

export function useNextSelectedSpoofReport() {
  const { nextSpoofReport } = useContext(SpoofReportTableFilterContext)
  const [nextSelectedSpoofReport, setNextSelectedSpoofReport] = nextSpoofReport
  return [nextSelectedSpoofReport, setNextSelectedSpoofReport]
}

export function spoofingReportsWhereClause(
  reportFilters: ReportFilter[],
  organizationID,
  reportStatuses: ReportStatus[],
  selectedReportType: ReportType,
  selectedAutocompleteReportId: string = null,
  selectedReportExposureType?: string,
  searchQuery = '',
): Spoofing_Reports_Bool_Exp {
  const twentyOneDaysAgo = moment()
    .startOf('day')
    .subtract(21, 'days')
    .format('YYYY-MM-DDTHH:mm:ss')
  if (reportStatuses.length == 0) {
    reportStatuses.push(ReportStatus.ENUM_NEEDS_REVIEW) // TODO set this in reports.tsx once enums have been consolidated
  }
  let whereClauses: any[] = []
  if (organizationID) {
    whereClauses.push({
      organization_id: { _eq: organizationID },
    })
  } else {
    // unified report view; filter to >= pitching
    whereClauses.push({
      organization: {
        organization_status: {
          _in: ALLOWED_ORG_STATUSES_FOR_UNIFIED_ALERTS,
        },
      },
    })
  }

  if (reportStatuses.length == 1) {
    whereClauses.push({
      report_status: {
        _eq: reportStatuses[0],
      },
    })
  } else {
    whereClauses.push({
      report_status: {
        _in: reportStatuses,
      },
    })
  }

  if (selectedAutocompleteReportId) {
    whereClauses.push({ id: { _eq: selectedAutocompleteReportId } })
  }

  // filter to selected product
  if (selectedReportType == ReportType.METAVERSE) {
    whereClauses.push({
      platform_subtype: { name: { _in: METAVERSE_PLATFORM_SUBTYPES } },
    })
  } else if (selectedReportType == ReportType.CODE_REPOS) {
    whereClauses.push({
      platform_subtype: { name: { _in: CODE_REPOS_PLATFORM_SUBTYPES } },
    })
  } else if (selectedReportType == ReportType.DARK_WEB && selectedReportExposureType) {
    whereClauses.push({
      platform_subtype: { name: { _eq: selectedReportExposureType } },
    })
  } else if (selectedReportType == ReportType.SUSPICIOUS_EMAILS) {
    whereClauses = whereClauses.concat(getEmailWhereClause(searchQuery))
  } else {
    whereClauses.push({
      platform_subtype: {
        product: { _eq: PLATFORM_TYPE_DISPLAY_MAP[selectedReportType] },
      },
    })
  }

  const reportFiltersByType = getAllFiltersByType(reportFilters)

  // add report assignment filters if needed
  Object.entries(reportFiltersByType).forEach(([filterType, filters]) => {
    switch (filterType) {
      case ReportFilterType.Organization: {
        if (!organizationID) {
          // only add this filter if we are in unified report view
          whereClauses.push({
            organization_id: { _in: filters.map((filter) => filter.value) },
          })
        }
        break
      }
      case ReportFilterType.OrgStatus: {
        if (!organizationID) {
          // only add this filter if we are in unified report view
          whereClauses.push({
            organization: {
              organization_status: {
                _in: filters.map((filter) => filter.value),
              },
            },
          })
        }
        break
      }
      case ReportFilterType.DateRange: {
        const filter = filters[0] // should have only one date range filter
        const dateRange: Date[] = filter.value.split('#').map((dateString) => {
          return moment(dateString).toDate()
        })
        dateRange[0].setHours(0, 0, 0, 0)
        dateRange[1].setHours(23, 59, 59, 999)

        whereClauses.push({
          created_at: {
            _lte: dateRange[1].toISOString(),
            _gte: dateRange[0].toISOString(),
          },
        })
        break
      }
      case ReportFilterType.Classification: {
        whereClauses.push({
          spoof_status: { _in: filters.map((filter) => filter.value) },
        })
        break
      }
      case ReportFilterType.PlatformSubtype: {
        whereClauses.push({
          platform_subtype_id: { _in: filters.map((filter) => filter.value) },
        })
        break
      }
      case ReportFilterType.DarkWebNetwork: {
        whereClauses.push({
          spoof_matches: {
            dark_web: {
              network: {
                _in: filters.map((filter) => filter.value),
              },
            },
          },
        })
        break
      }
      case ReportFilterType.DarkWebSource: {
        whereClauses.push({
          spoof_matches: {
            dark_web: {
              source: {
                _in: filters.flatMap((filter) => constructDarkOwlEnum(filter.value)),
              },
            },
          },
        })
        break
      }
      case ReportFilterType.Brand: {
        whereClauses.push({
          original_entity_id: { _in: filters.map((filter) => filter.value) },
        })
        break
      }
      case ReportFilterType.TLD: {
        const filter = filters[0] // should have only one TLD filter
        whereClauses.push({
          flagged_url: { _like: '%' + filter.value + '%' },
        })
        break
      }
      case ReportFilterType.Source: {
        const sources = filters.flatMap((filter) =>
          categoryToReportSources(filter.value as ReportSourceCategory),
        )
        whereClauses.push({
          source: { _in: sources },
        })
        break
      }
      case ReportFilterType.Uploader: {
        const filterValues = filters.map((filter) => filter.value)
        const hasDoppel = filterValues.includes('Doppel Analyst')
        const otherValues = filterValues.filter((value) => value !== 'Doppel Analyst')

        const uploaderCondition = hasDoppel
          ? otherValues.length > 0
            ? { _or: [{ is_internal: { _eq: true } }, { email: { _in: otherValues } }] }
            : { is_internal: { _eq: true } }
          : { email: { _in: filterValues } }

        whereClauses.push({ uploader: uploaderCondition })
        break
      }
      case ReportFilterType.Tag: {
        whereClauses.push({
          report_tags: {
            tag: {
              name: {
                _in: filters.map((filter) => filter.value),
              },
            },
          },
        })
        break
      }
      case ReportFilterType.DetectionReason: {
        whereClauses.push({
          spoof_matches: {
            ml_features: {
              name: {
                _in: filters.map((filter) => filter.value),
              },
              normalized_value: {
                _gte: 1,
              },
            },
          },
        })
        break
      }
      case ReportFilterType.Severity: {
        whereClauses.push({
          severity: { _in: filters.map((filter) => filter.value) },
        })
        break
      }
      case ReportFilterType.IsAged: {
        // Only one aged filter is allowed at a time
        const filter_val = filters[0].value
        if (filter_val === AlertAgeStatus.AGED) {
          whereClauses.push({
            _or: [
              { last_reported_at: { _lte: twentyOneDaysAgo } },
              { report_status: { _neq: ReportStatus.ENUM_REPORTED } },
            ],
          })
        } else if (filter_val === AlertAgeStatus.NOT_AGED) {
          whereClauses.push({
            _or: [
              { last_reported_at: { _gt: twentyOneDaysAgo } },
              { report_status: { _neq: ReportStatus.ENUM_REPORTED } },
            ],
          })
        }
        break
      }
      case ReportFilterType.Assignee: {
        const filterValues = filters.map((filter) => filter.value)

        let condition: any

        if (filterValues.length === 1 && filterValues[0] === ZERO_UUID) {
          // If the zero UUID is the only value, use _is_null condition directly
          condition = { internal_assignee_id: { _is_null: true } }
        } else {
          // Otherwise, construct the _in condition with optional _is_null
          if (filterValues.includes(ZERO_UUID)) {
            condition = {
              _or: [
                { internal_assignee_id: { _in: filterValues } },
                { internal_assignee_id: { _is_null: true } },
              ],
            }
          } else {
            condition = {
              internal_assignee_id: { _in: filterValues },
            }
          }
        }

        whereClauses.push(condition)
        break
      }
      case ReportFilterType.LinkedAlerts: {
        const filterValue = filters[0].value
        const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

        if (filterValue === 'true') {
          if (pathname.includes('/admin')) {
            whereClauses.push({ alert_links: {} })
          } else {
            whereClauses.push({
              alert_links: {
                alert_1: { organization_id: { _eq: organizationID } },
                alert_2: { organization_id: { _eq: organizationID } },
              },
            })
          }
        }
        break
      }
    }
  })
  return {
    _and: whereClauses,
  }
}

export const SpoofReportTableFilterProvider: React.FC<{ children }> = ({
  children,
}) => {
  const router = useRouter()
  const queryParams = new URLSearchParams(
    Object.entries(router.query).map(([key, value]) => [key, value.toString()]),
  )
  const { page } = parseQueryParams(queryParams)
  const [selectedPage, setSelectedPage] = useState(page || 1)
  const [selectedPageSize, setSelectedPageSize] = useState(STANDARD_PAGE_SIZES[0])
  const [selectedSpoofReport, setSelectedSpoofReport] = useState()
  const [nextSelectedSpoofReport, setNextSelectedSpoofReport] = useState()

  return (
    <SpoofReportTableFilterContext.Provider
      value={{
        page: [selectedPage, setSelectedPage],
        pageSize: [selectedPageSize, setSelectedPageSize],
        spoofReport: [selectedSpoofReport, setSelectedSpoofReport],
        nextSpoofReport: [nextSelectedSpoofReport, setNextSelectedSpoofReport],
      }}
    >
      {children}
    </SpoofReportTableFilterContext.Provider>
  )
}
