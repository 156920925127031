import { StatsGraphNames, OrganizationStatus } from '@/generated/enums'
import { DOPPEL_GREY } from '../utils/style'

export type Dataset = {
  label: string
  values: number[]
}

const DOPPEL_SLATE = '#848EA0'
const DOPPEL_LIGHT_BLUE = '#629DEB'
const DOPPEL_LIGHT_PURPLE = '#7778EA'
const DOPPEL_SEA_BLUE = '#B0D5FA'
const DOPPEL_MAIN_BLUE_LIGHT_SHADE = '#336AEA'

export const STACKED_BAR_CHART_MAX_GROUP_SIZE = 6

export const STACKED_BAR_CHART_COLORS = [
  DOPPEL_MAIN_BLUE_LIGHT_SHADE,
  DOPPEL_LIGHT_BLUE,
  DOPPEL_GREY,
  DOPPEL_SLATE,
  DOPPEL_LIGHT_PURPLE,
  DOPPEL_SEA_BLUE,
]

export const ORGANIZATION_STATUSES_WITH_ANALYTICS = [
  OrganizationStatus.ACTIVE,
  OrganizationStatus.EVALUATING,
  OrganizationStatus.PITCHING,
  OrganizationStatus.TRIAL_ENDED,
  OrganizationStatus.TEST_ORG,
]

export const chartNameToDescriptionMap: Record<StatsGraphNames, string> = {
  [StatsGraphNames.REPORTS_SURFACED]:
    'Shows when reports are first surfaced in one of the report queues.',
  [StatsGraphNames.MEDIAN_TAKEDOWN_TIME]:
    'Shows the median time to take down a report (first reported to first resolved).',
}

export const isMultiSelectFilterForChartMapping: Record<StatsGraphNames, boolean> = {
  [StatsGraphNames.REPORTS_SURFACED]: true,
  [StatsGraphNames.MEDIAN_TAKEDOWN_TIME]: false,
}

export const sortFilterObject = (obj) => {
  const sortedObj = {}
  Object.keys(obj)
    .sort()
    .forEach((key) => {
      if (Array.isArray(obj[key].in_)) {
        sortedObj[key] = { in_: [...obj[key].in_].sort() }
      } else {
        sortedObj[key] = obj[key]
      }
    })
  return sortedObj
}

const MIN_INTERVAL_BOUND = 5
const MAX_INTERVALS = 8
const WHOLE_INCREMENTS = 10

export function getYAxisTicks(
  datasetValues: Record<string, Record<string, number>>,
  xAxisLabels: string[],
): number[] {
  const datasets: Dataset[] = Object.entries(datasetValues).map(([label, values]) => ({
    label,
    values: xAxisLabels.map((xLabel) => values[xLabel] || 0),
  }))

  const maxValuesPerIndex = xAxisLabels.map((_, i) => {
    const sumAcrossDatasets = datasets.reduce(
      (sum, dataset) => sum + dataset.values[i],
      0,
    )
    return sumAcrossDatasets
  })

  const maxValue = Math.max(...maxValuesPerIndex)
  let interval = MIN_INTERVAL_BOUND
  while (maxValue > interval * MAX_INTERVALS) {
    if (interval % WHOLE_INCREMENTS === MIN_INTERVAL_BOUND) {
      interval += MIN_INTERVAL_BOUND
    } else {
      interval += WHOLE_INCREMENTS
    }
  }

  let numIntervals = Math.ceil(maxValue / interval)
  numIntervals = Math.min(numIntervals, MAX_INTERVALS)

  const yAxisLabels: number[] = Array.from(
    { length: numIntervals + 1 },
    (_, i) => i * interval,
  )

  return yAxisLabels
}

export const formatTopItems = <T extends { label: string; value: number }>(
  data: T[] | null,
  count = 5,
  defaultLabel = '',
  defaultValue = 0,
): T[] => {
  if (!data || !Array.isArray(data)) {
    return Array(count).fill({ label: defaultLabel, value: defaultValue }) as T[]
  }

  const formattedData = data.map((item) => ({
    ...item,
    label: item.label,
    value: Number(item.value.toFixed(1)),
  }))

  while (formattedData.length < count) {
    formattedData.push({ label: defaultLabel, value: defaultValue } as T)
  }

  return formattedData.slice(0, count)
}
