import {
  DetectionRuleType,
  PlatformName,
  PlatformSubtype,
  RuleFieldType,
} from '@/generated/enums'

export enum KeyCode {
  Enter = 13,
  ArrowLeft = 37,
  ArrowRight = 39,
  Escape = 27,
  ArrowDown = 40,
}

// TODO: backend report type to be eventually merged with ReportType
export enum CollectionReportType {
  COUNTERFEIT = 'COUNTERFEIT',
  NSFW = 'NSFW', // DEPRECATED
}

export enum ReportType {
  NFTS = 'NFTs',
  DOMAINS = 'Domains',
  SOCIAL_MEDIA = 'Social Media',
  ECOMMERCE = 'E-Commerce',
  EMAIL = 'Email',
  MOBILE_APPS = 'Apps',
  PAID_ADS = 'Paid Ads',
  CRYPTO = 'Crypto',
  METAVERSE = 'Gaming',
  DARK_WEB = 'Dark Web',
  DARK_MARKET = 'Dark Market',
  CODE_REPOS = 'Code Repos',
  TELCO = 'Telco',
  SUSPICIOUS_EMAILS = 'Suspicious Emails',
}

export enum CSVType {
  PROTECTED_ASSET = 'PROTECTED_ASSET',
}

export enum AssetsTab {
  COLLECTIONS = 'NFT Collections',
  BRANDS = 'Brands',
  PROTECTED_ASSETS = 'Protected Assets',
  TRADEMARKS = 'Trademarks',
  DETECTION_ASSETS = 'Detection Assets',
  SOURCING_QUERIES = 'Sourcing Queries',
}

export enum StatsTab {
  ALL_MODULES = 'All Modules',
  OVERVIEW = 'Overview',
  TRENDS = 'Trends',
  DASHBOARD = 'Dashboard Mockup',
  PROCESSING_TIME = 'Processing Time',
  REPORTS = 'Reports',
  CRED_THEFT = 'Cred Theft',
  BRAND_ABUSE = 'Brand Abuse',
  SOCIAL_MEDIA = 'Social Media',
  MOBILE_APPS = 'Mobile Apps',
  SUSPICIOUS_EMAILS = 'Suspicious Emails',
}

export const StatsTabLabels: { [key in StatsTab]?: string } = {
  [StatsTab.ALL_MODULES]: 'All Modules',
  [StatsTab.OVERVIEW]: 'Overview',
  [StatsTab.TRENDS]: 'Trends',
  [StatsTab.DASHBOARD]: 'Dashboard Mockup',
  [StatsTab.PROCESSING_TIME]: 'Processing Time',
  [StatsTab.REPORTS]: 'Reports',
  [StatsTab.CRED_THEFT]: 'Credential Theft',
  [StatsTab.BRAND_ABUSE]: 'Brand Abuse',
  [StatsTab.SOCIAL_MEDIA]: 'Social Media',
  [StatsTab.MOBILE_APPS]: 'Mobile Apps',
  [StatsTab.SUSPICIOUS_EMAILS]: 'Suspicious Emails',
}

export enum OrganizationTab {
  USERS = 'Users',
  TAGS = 'Tags',
}

export enum SocialMediaPlatform {
  NONE = 'NONE',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
  WHATSAPP = 'WHATSAPP',
  REDDIT = 'REDDIT',
  YOUTUBE = 'YOUTUBE',
  TELEGRAM = 'TELEGRAM',
  DISCORD = 'DISCORD',
  APPLE_APP_STORE = 'APPLE_APP_STORE',
  GOOGLE_APP_STORE = 'GOOGLE_APP_STORE',
  TUMBLR = 'TUMBLR',
  MEDIUM = 'MEDIUM',
  SPOTIFY = 'SPOTIFY',
}

// System audit trail status. This is used to track where the report is in our system.
// TODO: make report statuses consistent between spoof reports + nft reports
export enum ReportStatus {
  NEEDS_REVIEW = 'Needs Review',
  NEEDS_CONFIRMATION = 'In Progress',
  REPORTED = 'Reported',
  RESOLVED = 'Resolved',
  APPROVED_DERIVATIVE = 'Approved Derivative',
  ARCHIVED = 'Archived',
  INTERNAL_REVIEW = 'Internal Review',
  INTERNAL_BAD_MATCH = 'Internal Ignored',
  INTERNAL_ARCHIVED = 'Internal Archived',
  ENUM_NEEDS_REVIEW = 'needs_review',
  ENUM_NEEDS_CONFIRMATION = 'needs_action', // equivalent to In Progress
  ENUM_REPORTED = 'reported',
  ENUM_RESOLVED = 'resolved',
  ENUM_APPROVED_DERIVATIVE = 'approved_derivative',
  ENUM_ARCHIVED = 'archived',
  ENUM_INTERNAL_REVIEW = 'internal_review',
  ENUM_INTERNAL_BAD_MATCH = 'internal_ignored',
  ENUM_INTERNAL_ARCHIVED = 'internal_archived',
}

export const SUSPICIOUS_EMAILS_REPORT_STATUSES = [
  ReportStatus.ENUM_NEEDS_REVIEW,
  ReportStatus.ENUM_NEEDS_CONFIRMATION,
  ReportStatus.ENUM_ARCHIVED,
  ReportStatus.ENUM_APPROVED_DERIVATIVE,
  ReportStatus.ENUM_REPORTED,
]

export enum OrganizationStatus {
  ACTIVE = 'active',
  EVALUATING = 'evaluating',
  PITCHING = 'pitching',
  TRIAL_ENDED = 'trial_ended',
  INACTIVE = 'inactive',
  TEST_ORG = 'test_org',
}

export enum OrganizationType {
  MARKETPLACE = 'marketplace',
  BRAND = 'brand',
}

export function getStatusLabel(
  reportStatus,
  selectedType,
  labels: Record<string, string> = STATUS_TO_HEADER_LABEL,
) {
  const statusLabelOverrides = {
    [ReportType.DARK_WEB]: {
      [ReportStatus.REPORTED]: 'Actioned',
      [ReportStatus.ENUM_REPORTED]: 'Actioned',
    },
    [ReportType.SUSPICIOUS_EMAILS]: {
      [ReportStatus.ENUM_REPORTED]: 'Malicious',
      [ReportStatus.ENUM_ARCHIVED]: 'No Threat Detected',
      [ReportStatus.ENUM_NEEDS_CONFIRMATION]: 'Needs Confirmation',
      [ReportStatus.ENUM_APPROVED_DERIVATIVE]: 'Do Not Engage',
    },
  }

  const typeOverrides = statusLabelOverrides[selectedType] || {}

  return { ...labels, ...typeOverrides }[reportStatus]
}

export const STATUS_TO_HEADER_LABEL = {
  [ReportStatus.NEEDS_REVIEW]: 'Doppel Review',
  [ReportStatus.NEEDS_CONFIRMATION]: 'Needs Confirmation',
  [ReportStatus.REPORTED]: 'Actioned',
  [ReportStatus.RESOLVED]: 'Taken Down',
  [ReportStatus.APPROVED_DERIVATIVE]: 'Monitoring',
  [ReportStatus.ARCHIVED]: 'Archived',
  [ReportStatus.INTERNAL_REVIEW]: 'Internal Review',
  [ReportStatus.INTERNAL_BAD_MATCH]: 'Internal Bad Match',
  [ReportStatus.ENUM_NEEDS_REVIEW]: 'Doppel Review',
  [ReportStatus.ENUM_INTERNAL_REVIEW]: 'Internal Review',
  [ReportStatus.ENUM_INTERNAL_ARCHIVED]: 'Internal Archived',
  [ReportStatus.ENUM_APPROVED_DERIVATIVE]: 'Monitoring',
  [ReportStatus.ENUM_ARCHIVED]: 'Archived',
  [ReportStatus.ENUM_INTERNAL_BAD_MATCH]: 'Internal Bad Match',
  [ReportStatus.ENUM_REPORTED]: 'Actioned',
  [ReportStatus.ENUM_RESOLVED]: 'Taken Down',
  [ReportStatus.ENUM_NEEDS_CONFIRMATION]: 'Needs Confirmation',
  ['primary_only']: 'Primary Only',
  ['monitoring_only']: 'Monitoring Only',
}

export const STATUS_ACTION_LABELS = {
  [ReportStatus.ENUM_NEEDS_REVIEW]: 'Doppel Review',
  [ReportStatus.ENUM_INTERNAL_REVIEW]: 'Internal Review',
  [ReportStatus.ENUM_INTERNAL_ARCHIVED]: 'Internal Archived',
  [ReportStatus.ENUM_APPROVED_DERIVATIVE]: 'Monitor',
  [ReportStatus.ENUM_ARCHIVED]: 'Archive',
  [ReportStatus.ENUM_INTERNAL_BAD_MATCH]: 'Internal Bad Match',
  [ReportStatus.ENUM_REPORTED]: 'Action',
  [ReportStatus.ENUM_RESOLVED]: 'Take Down',
  [ReportStatus.ENUM_NEEDS_CONFIRMATION]: 'Needs Confirmation',
}

export const COLLECTION_REPORT_TYPE = {
  NSFW: 'COLLECTION_REPORT_TYPE.NSFW', // DEPRECATED
  LOGO: 'COLLECTION_REPORT_TYPE.LOGO',
  COUNTERFEIT: 'COLLECTION_REPORT_TYPE.COUNTERFEIT',
}

export const CHAIN_ADDRESSES = {
  eth: 'https://etherscan.io/address/',
  matic: 'https://polygonscan.com/address/',
  klaytn: 'https://scope.klaytn.com/account/',
  sol: 'https://solscan.io/account/',
}

export enum ReportFilterType {
  Search = 'Search',
  DateRange = 'Date',
  ReportOwner = 'Report Owner',
  Collection = 'Collection',
  Brand = 'Brand',
  MatchType = 'Match Type',
  ShowAll = 'Show All',
  Chain = 'Chain',
  NumItems = 'Num Items',
  Classification = 'Classification',
  Platform = 'Platform',
  PlatformSubtype = 'Platform',
  Product = 'Product',
  TLD = 'TLD',
  Tag = 'Tag',
  Type = 'Type',
  Stage = 'Stage',
  Organization = 'Organization',
  DarkWebNetwork = 'Network',
  DarkWebSource = 'Dark Web Source',
  Source = 'Source',
  OrgStatus = 'Org Status',
  DetectionReason = 'Reason',
  Severity = 'Severity',
  Uploader = 'Uploader',
  IsAged = 'Age',
  Assignee = 'Assignee',
  LinkedAlerts = 'Linked Alerts',
}

export enum EnforcementPlatformFilterType {
  EnforcementType = 'Enforcement Type',
  InitialEnforcementMethod = 'Initial Enforcement Method',
  PlatformType = 'Platform Type',
  ProductType = 'Product Type',
}

export enum EntityType {
  ORGANIZATION_BRAND = 'organization_brand',
  NFT_COLLECTION = 'nft_collection',
  PERSONAL_BRAND = 'personal_brand',
}

export enum STAT_DATE_UNIT {
  DAYS = 'Days',
  MONTHS = 'Months',
}

export const DOLLARS_TO_CRYPTO_RATIO = {
  eth: 1868.0,
  matic: 0.646,
  klaytn: 0.1689,
  sol: 16.26,
  klay: 0.1689,
  flow: 0.5578,
  bsc: 0.0018,
  arbitrum: 1.18,
  avalanche: 12.98,
  optimism: 1.27,
  apt: 7.21,
  arbitrum_nova: 0.6698,
  base: 1.04,
}

export const TEST_ORG_ID = '63cb7d78-5c26-4362-afe7-8161569751e3'
export const DAPPER_ORG_ID = '3c597720-4e13-45d6-a532-64f6b83d51bb'
export const OPENSEA_ORG_ID = '4c49203e-ecd6-406f-97a7-b68418b2a7fb'
export const YUGA_ORG_ID = '7a1a2227-40bc-4f90-8230-01e67582e6a9'
export const DISNEY_OLD_2_ORG_ID = '1cbf9aee-e5c1-488b-b890-c7e943e6ae51'
export const DISNEY_ORG_ID = '5298ec8a-a5dc-4694-9283-5209ffd06f4b'
export const VISA_ORG_ID = '829997a1-ef05-4224-9a2b-9483e8461240'
export const NBA_ORG_ID = 'a9ff9784-5bb8-475b-a172-a312debf1329'
export const PROOF_ORG_ID = '817d4828-959f-4fde-9d5f-31ee2bf2fe1d'
export const LOUIS_VUITTON_ORG_ID = '24c48911-5b5c-4d77-9d8d-280256f811db'
export const MAKERSPLACE_ORG_ID = '701288cb-6ca3-4933-addf-669f800be301'
export const RECUR_ORG_ID = 'dafa3933-b7e7-4abf-a9ab-6a8a9d640839'
export const META_ORG_ID = 'e0d3b702-cd03-458b-8f53-dfa3b1e2b0c1'
export const MYSTEN_LABS_ID = '1eb7d22a-c927-42c9-b669-c7b8eb771a4b'
export const STARBUCKS_ORG_ID = '8a9038e2-ca53-4289-a63a-6b7fa9f92afa'
export const UNISWAP_ORG_ID = '71f7a4d7-ca6b-4e88-9a1d-c9a382496427'
export const ART_BLOCKS_ORG_ID = '252041a6-7921-4a07-80a6-969ab739b0af'
export const NOTION_ORG_ID = '240fd803-4664-474a-937e-1c6f70286aee'

export const DNS_PROVIDERS = [
  'fcdb249e-9c2f-43ff-916f-de5dce388c7d', // ENS
  '8a6c3644-463d-4507-9df0-fd7980747b6e', // Unstoppable Domains
]

export const SLACK_CUSTOMER_NOTIF_ID = 'C04M54B8ZL1'
export const SLACK_CUSTOMER_TRIAL_NOTIF_ID = 'C0801942Y5T'
export const PRODUCT_REQUESTS_CHANNEL_ID = 'C0703RWHY04'

/**
 * UNCLASSIFIED - Domain/account has been discovered but not classified yet, temporary state
 * DOWN - no content, returns an error
 * PARKED - domain registered but not used / for sale
 * UNRELATED - domain / social media account has unrelated content
 * UNKNOWN - domains that are not down or parked, but we can't automatically determine the content
 * SUSPICIOUS - social media / ugc / domain has seemingly infringing content
 * RELATED - domains that have similar content but we can't determine if it's infringing
 */
export enum Classification {
  SUSPICIOUS = 'suspicious',
  RELATED = 'related',
  UNKNOWN = 'unknown',
  UNRELATED = 'unrelated',
  PARKED = 'parked',
  DOWN = 'down',
  UNCLASSIFIED = 'unclassified',
  ACTIVE = 'active',
}

export const CLASSIFICATION_TO_LABEL = {
  [Classification.SUSPICIOUS]: 'suspicious',
  [Classification.RELATED]: 'unverified',
  [Classification.UNKNOWN]: 'unverified',
  [Classification.UNRELATED]: 'unverified',
  [Classification.PARKED]: 'parked',
  [Classification.DOWN]: 'down',
  [Classification.UNCLASSIFIED]: 'unverified',
  [Classification.ACTIVE]: 'active',
}

export enum Severity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const SEVERITY_TO_LABEL = {
  [Severity.LOW]: 'low',
  [Severity.MEDIUM]: 'medium',
  [Severity.HIGH]: 'high',
}

export enum DarkWebNetwork {
  UNKNOWN = 'UNKNOWN',
  DISCORD = 'DISCORD',
  FTP = 'FTP',
  I2P = 'I2P',
  IRC = 'IRC',
  ONION_V2 = 'ONION.V2',
  ONION_V3 = 'ONION.V3',
  OPENNIC = 'OPENNIC',
  TELEGRAM = 'TELEGRAM',
  UNCLASSIFIED = 'UNCLASSIFIED',
  ZERONET = 'ZERONET',
}

export enum DarkWebSource {
  UNKNOWN = 'UNKNOWN',
  DARKOWL_EMAIL = 'EMAIL',
  DARKOWL_RANSOMWARE = 'RANSOMWARE',
  DARKOWL_SEARCH = 'SEARCH',
}

export enum DarkWebPlatforms {
  CRED_LEAKS = 'cred_leaks',
  CREDIT_CARD_LEAKS = 'credit_card_leaks',
  API_LEAKS = 'api_leaks',
  GENERIC = 'darkweb',
}

export const DarkWebPlatformLabel = {
  [DarkWebPlatforms.CRED_LEAKS]: 'Credential Leaks',
  [DarkWebPlatforms.CREDIT_CARD_LEAKS]: 'Credit Card Leaks',
  [DarkWebPlatforms.API_LEAKS]: 'API Leaks',
  [DarkWebPlatforms.GENERIC]: 'Other Leaks',
}

export enum Marketplace {
  OPENSEA = 'opensea',
  RARIBLE = 'rarible',
}

export type EnforcementPlatform = DomainPlatform | Marketplace | SocialMediaPlatform

export enum DomainPlatform {
  GOOGLE = 'google',
  BING = 'bing',
  METAMASK = 'metamask',
  PHANTOM = 'phantom',
  NAMECHEAP = 'namecheap',
  NAMESILO = 'namesilo',
  GODADDY = 'godaddy',
  TUCOWS = 'tucows',
  PORKBUN = 'porkbun',
  CLEANDNS = 'cleandns',
  PUBLIC_DOMAIN_REGISTRY = 'public_domain_registry',
  HOSTINGER = 'hostinger',
  SAV = 'sav',
  GNAME = 'gname',
  DYNADOT = 'dynadot',
  NICENIC = 'nicenic',
  CLOUDFLARE = 'cloudflare',
  SAREK_OY = 'sarek_oy',
  EPIK = 'epik',
  CUSTOM = 'custom',
  COINBASE = 'coinbase',
  SQUARESPACE = 'squarespace',
}

export enum ENFORCEMENT_TYPE {
  PHISHING = 'phishing',
  TRADEMARK = 'trademark',
  COPYRIGHT = 'copyright',
  IMPERSONATION = 'impersonation',
  COUNTERFEIT = 'counterfeit',
  PRODUCT_MISUSE = 'product_misuse',
  UNDERAGE_USE = 'underage_use',
  DMCA = 'dmca',
}

export enum ENFORCEMENT_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REPORTED = 'reported',
  BLOCKED = 'blocked',
  STAGED = 'staged',
  FAILED = 'failed',
  CANCELED = 'canceled',
  REROUTED = 'rerouted',
  RETRACTED = 'retracted',
  RETRACTION_SENT = 'retraction_sent',
}

export enum ApprovalsStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REQUESTED = 'requested',
  NOT_REQUESTED = 'not_requested',
}

export enum ProductType {
  DOMAIN = 'domains',
  SOCIAL_MEDIA = 'social_media',
  MOBILE_APPS = 'mobile_apps',
  EMAIL = 'email',
  CRYPTO = 'crypto',
  PAID_ADS = 'paid_ads',
  NFT = 'nft',
  ECOMMERCE = 'ecommerce',
  METAVERSE = 'metaverse',
  DARK_WEB = 'darkweb',
  DARK_MARKET = 'darkmarket',
  CODE_REPOS = 'code_repos',
  TELCO = 'telco',
  IGNORED = 'ignored',
  SUSPICIOUS_EMAILS = 'suspicious_emails',
}

export const SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS = [
  ProductType.DOMAIN,
  ProductType.MOBILE_APPS,
  ProductType.SOCIAL_MEDIA,
  ProductType.PAID_ADS,
]

export const MANUAL_ENFORCEMENT_DISABLED_PRODUCTS = [
  ProductType.DOMAIN,
  ProductType.SOCIAL_MEDIA,
]

export enum ChangeType {
  REPORT_STATUS = 'report_status',
  REPORT_CREATE = 'report_create',
}

export enum FileUploadType {
  REPORT_SCREENSHOT = 'report_screenshot',
}

export enum ExternalSource {
  RAPID7 = 'rapid7',
  FLASHPOINT = 'flashpoint',
  AWS = 'aws',
}

export enum ReportTagName {
  APP_VULNERABILITY = 'app_vulnerability',
  PLATFORM_ABUSE = 'platform_abuse',
  ACCOUNT_TAKEOVER = 'account_takeover',
  NITRO_ABUSE = 'nitro_abuse',
  SOCIAL_ENGINEERING = 'social_engineering',
}

// TODO: remove this filter once we add score to all other report types
export const SCORE_ENABLED_REPORT_TYPES = new Set([
  ReportType.DOMAINS,
  ReportType.SOCIAL_MEDIA,
  ReportType.MOBILE_APPS,
])

export const HASURA_MAX_INT = 2_147_483_647

export enum CollectionReportChangeType {
  ENFORCEMENT_REQUEST_STATUS = 'enforcement_request_status',
  IMPORTANCE_SCORE = 'importance_score',
  MAX_SIMILARITY_SCORE = 'max_similarity_score',
  SCORE = 'score',
  SPOOF_STATUS = 'spoof_status',
  REPORT_STATUS = 'report_status',
  NOTES = 'notes',
}

export enum EnforcementRequestChangeSource {
  AUTOMATED_TAKEDOWN = 'automated_takedown',
}

export const SUPPORTED_AUTO_FORM_ENFORCEMENT_OPTIONS = [
  [SocialMediaPlatform.TWITTER.toLowerCase(), ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()],
  [SocialMediaPlatform.TIKTOK.toLowerCase(), ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()],
  [SocialMediaPlatform.TIKTOK.toLowerCase(), ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()],
  // [SocialMediaPlatform.YOUTUBE.toLowerCase(), ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()],
]

export const SUPPORTED_API_ENFORCEMENT_OPTIONS = [
  [DomainPlatform.CLOUDFLARE, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.CLOUDFLARE, ENFORCEMENT_TYPE.TRADEMARK],
  [DomainPlatform.CLOUDFLARE, ENFORCEMENT_TYPE.COPYRIGHT],
  [DomainPlatform.GODADDY, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.GOOGLE, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.NAMECHEAP, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.CLEANDNS, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.COINBASE, ENFORCEMENT_TYPE.PHISHING],
  [DomainPlatform.METAMASK, ENFORCEMENT_TYPE.PHISHING],
  [
    SocialMediaPlatform.INSTAGRAM.toLowerCase(),
    ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase(),
  ],
  [
    SocialMediaPlatform.INSTAGRAM.toLowerCase(),
    ENFORCEMENT_TYPE.TRADEMARK.toLowerCase(),
  ],
  [
    SocialMediaPlatform.FACEBOOK.toLowerCase(),
    ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase(),
  ],
  [
    SocialMediaPlatform.FACEBOOK.toLowerCase(),
    ENFORCEMENT_TYPE.TRADEMARK.toLowerCase(),
  ],
]

export const STANDARD_PAGE_SIZES = [30, 50, 100]

export enum PageRoute {
  TRADEMARKS = '/trademarks',

  // admin routes
  AUTOMATED_TAKEDOWNS = '/enforcements/automated-takedowns',
  ORGANIZATIONS = '/admin/organizations',
}

export enum MetaReportingContentType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  ARTWORK = 'ARTWORK',
  SOFTWARE = 'SOFTWARE',
  NAME = 'NAME',
  CHARACTER = 'CHARACTER',
  OTHER = 'OTHER',
}

export const PROMPT_PLATFORM_OPTIONS = [
  PlatformName.DOMAIN,
  PlatformName.TWITTER,
  PlatformName.INSTAGRAM,
  PlatformName.FACEBOOK,
  PlatformName.YOUTUBE,
  PlatformName.SOCIAL_MEDIA,
]

export const REPORTING_AGENT = 'Doppel SOC Team'

export const DOMAIN_PLATFORM = 'domain'

export const ZERO_UUID = '00000000-0000-0000-0000-000000000000'

export const enum JIRA_TICKET_STATUS {
  TO_DO = 'To Do',
  IN_REVIEW = 'In Review',
  DONE = 'Done',
  BLOCKED = 'Blocked',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
  ARCHIVED = 'Archived',
}

export const RETRACTION_REASONS = [
  'Asset has been verified as official and belonging to the customer.',
  "Asset was inadvertently reported, but does not pertain to customer's brand.",
]

export const PRODUCT_SPECIFIC_RETRACTION_REASONS = new Map<ProductType, string[]>([
  [
    ProductType.DOMAIN,
    [
      'The reported site is a affiliated/Vendor site of our customer. (HR/Payroll/etc.)',
      "The reported site is using our customer's name for Research/Study purposes but does not infringe our customer (blogs/news/etc.)",
    ],
  ],
  [
    ProductType.SOCIAL_MEDIA,
    [
      "Fan page/account account that does not belong to our customer but we don't want to takedown.",
      "Infringing post(s)/content has been removed and we kindly request to please reactivate the user's account.",
    ],
  ],
])

export const DOMAIN_DETECTION_RULE_FIELD_NAMES = [
  {
    value: 'unsanitized_url',
    label: 'Unsanitized URL',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'cleaned_url',
    label: 'Cleaned URL',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'source',
    label: 'Source',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'sourcing_query',
    label: 'Sourcing Query',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'sourcer_label',
    label: 'Sourcer Label',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'alert_status',
    label: 'Current Alert Status',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [DetectionRuleType.ALERT_UPDATE],
  },
  {
    value: 'suspicion_score',
    label: 'Suspicion Score',
    fieldType: RuleFieldType.NUMERIC,
    supportedRuleTypes: [DetectionRuleType.ALERT_UPDATE],
  },
  {
    value: 'website_text',
    label: 'Website Text',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'website_html',
    label: 'Website HTML',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'html_ssdeep_hash',
    label: 'HTML SSDeep Hash',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'scraped_http_status_code',
    label: 'HTTP Status Code',
    fieldType: RuleFieldType.NUMERIC,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_url',
    label: 'Root Domain URL',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_ip_address',
    label: 'Root Domain IP Address',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_registrar_name',
    label: 'Root Domain Registrar Name',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_external_created_date',
    label: 'Root Domain External Created Date',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_contact_email',
    label: 'Root Domain Contact Email',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_country_code',
    label: 'Root Domain Country Code',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_hosting_provider',
    label: 'Root Domain Hosting Provider',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_asn',
    label: 'Root Domain ASN',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
  {
    value: 'root_domain_data_iana_id',
    label: 'Root Domain IANA ID',
    fieldType: RuleFieldType.TEXT,
    supportedRuleTypes: [
      DetectionRuleType.ALERT_CREATION,
      DetectionRuleType.ALERT_UPDATE,
    ],
  },
]

export const DETECTION_RULE_OPERATORS = [
  // Text & numeric operators
  {
    value: 'equal_to',
    label: 'Equal To',
    validFieldTypes: [RuleFieldType.TEXT, RuleFieldType.NUMERIC],
  },
  {
    value: 'not_equal_to',
    label: 'Not Equal To',
    validFieldTypes: [RuleFieldType.TEXT, RuleFieldType.NUMERIC],
  },
  // Text operators
  {
    value: 'equal_to_case_insensitive',
    label: 'Equal To (case insensitive)',
    validFieldTypes: [RuleFieldType.TEXT],
  },
  {
    value: 'not_equal_to_case_insensitive',
    label: 'Not Equal To (case insensitive)',
    validFieldTypes: [RuleFieldType.TEXT],
  },
  { value: 'contains', label: 'Contains', validFieldTypes: [RuleFieldType.TEXT] },
  { value: 'starts_with', label: 'Starts With', validFieldTypes: [RuleFieldType.TEXT] },
  { value: 'ends_with', label: 'Ends With', validFieldTypes: [RuleFieldType.TEXT] },
  {
    value: 'matches_regex',
    label: 'Matches Regex',
    validFieldTypes: [RuleFieldType.TEXT],
  },
  // Numeric operators
  {
    value: 'greater_than',
    label: 'Greater Than',
    validFieldTypes: [RuleFieldType.NUMERIC],
  },
  {
    value: 'greater_than_or_equal_to',
    label: 'Greater Than or Equal To',
    validFieldTypes: [RuleFieldType.NUMERIC],
  },
  { value: 'less_than', label: 'Less Than', validFieldTypes: [RuleFieldType.NUMERIC] },
  {
    value: 'less_than_or_equal_to',
    label: 'Less Than or Equal To',
    validFieldTypes: [RuleFieldType.NUMERIC],
  },
]

export const LOGICAL_OPERATORS = [
  { value: 'any', label: 'Any (OR)' },
  { value: 'all', label: 'All (AND)' },
  { value: 'not', label: 'Not' },
]

export const ACTION_TYPES_AND_PARAMS = [
  {
    name: 'update_alert_status',
    label: 'Update Alert Status',
    param_name: 'new_alert_status',
    params: [
      { value: 'needs_review', label: 'Doppel Review' },
      { value: 'needs_action', label: 'Needs Confirmation' },
      { value: 'approved_derivative', label: 'Monitoring' },
      { value: 'reported', label: 'Actioned' },
      { value: 'resolved', label: 'Taken Down' },
      { value: 'archived', label: 'Archived' },
      { value: 'internal_review', label: 'Internal Review' },
      { value: 'internal_ignored', label: 'Internal Bad Match' },
      { value: 'internal_archived', label: 'Internal Archived' },
    ],
    supportedRuleTypes: [DetectionRuleType.ALERT_UPDATE],
  },
  {
    name: 'add_alert_tag',
    label: 'Tag Alert',
    param_name: 'alert_tag_id',
    params: [
      { value: '703d49b6-489a-470e-ac23-25ff9a859153', label: 'Credential Theft' },
      { value: 'caf81a07-1f29-4436-a292-496d47fae145', label: 'Redirect' },
      { value: '91d7c5c6-1f97-4fde-ae40-f56bdbbcccaa', label: 'Brand Infringement' },
      { value: '97bfa665-c13a-4bb7-8722-002318a0b43a', label: 'Typosquatted Domain' },
      { value: '8c95c411-512d-4b3a-b04e-deb7d807d9b2', label: 'Domain' },
      { value: 'fa24341c-1d76-4555-b7b0-3377b0e1fcb7', label: 'URL' },
      { value: '2814a235-60ae-4e5c-8781-fe7f0db47af8', label: 'Domain and URL' },
    ],
    supportedRuleTypes: [DetectionRuleType.ALERT_UPDATE],
  },
  {
    name: 'create_alert',
    label: 'Create Alert',
    supportedRuleTypes: [DetectionRuleType.ALERT_CREATION],
  },
  {
    name: 'skip_alert_creation',
    label: 'Skip Alert Creation',
    supportedRuleTypes: [DetectionRuleType.ALERT_CREATION],
  },
]

export const AD_PLATFORMS_MANUAL_AND_AUTO_SOURCED = [PlatformSubtype.GOOGLE_ADS]
