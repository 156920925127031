import { Severity, SEVERITY_TO_LABEL } from '@/utils/constants'
import { EnforcementRequestStatus, ReportClassification } from '@/generated/enums'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_FIREWALL_ORANGE,
  DOPPEL_ACTIVE_SILVER,
  DOPPEL_INACTIVE_GREY,
  DOPPEL_BUTTON_GREY,
  DOPPEL_DIM_YELLOW,
  DOPPEL_TEXT_WHITE,
  DOPPEL_CLOUD_BLUE,
  DOPPEL_ENCRYPT_GREEN_SHADE,
  DOPPEL_INTERNAL_PURPLE,
  FONT_SIZE_SMALL,
  DOPPEL_WHITE,
} from '../../utils/style'
import {
  Spinner,
  Tag,
  Box,
  MenuButton,
  Icon,
  Tooltip,
  TagProps,
} from '@chakra-ui/react'
import { unSnakeCase } from '@/utils/string_utils'
import { IoIosClose } from 'react-icons/io'
import { Dropdown, DropdownItem } from '../detailed_view/dropdown'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useGetUsersQuery } from '@/generated/graphql'
import { getSortedAlertAssigneesWithUnassigned } from '@/utils/enforcement_utils'
import { useUserID } from '@/hooks/id_token_claims'

type DoppelTagProps = {
  tag: React.ReactNode
  loading?: boolean
  bgColor?: string
  color?: string
  width?: string
  height?: string
  onDelete?: () => Promise<void>
} & TagProps

export function DoppelTag({
  tag,
  loading,
  bgColor,
  color,
  width,
  height,
  onDelete,
  ...tagProps
}: DoppelTagProps) {
  const defaultBgColor = DOPPEL_BUTTON_GREY
  const defaultColor = DOPPEL_TEXT_WHITE

  return (
    <Tag
      borderRadius="4px"
      fontSize={FONT_SIZE_SMALL}
      fontWeight={600}
      paddingX={2}
      paddingY={1}
      style={{
        ...(width && { width: width }),
        ...(height && { height: height }),
        backgroundColor: bgColor ?? defaultBgColor,
        color: color ?? defaultColor,
      }}
      {...tagProps}
    >
      {loading ? <Spinner size="xs" /> : tag}

      {onDelete && (
        <Box
          as={IoIosClose}
          borderRadius="4px"
          cursor="pointer"
          ml={1}
          mt={'0.5px'}
          onClick={onDelete}
          padding={0}
          style={{
            width: 'inherit',
            height: 'inherit',
          }}
        />
      )}
    </Tag>
  )
}

type DoppelTagWithDropdownProps = {
  dropdownItems: DropdownItem[]
  onDropdownItemSelect: (value: string) => void
  showDropdownChevron?: boolean
} & DoppelTagProps

export function DoppelTagWithDropdown({
  tag,
  dropdownItems,
  onDropdownItemSelect,
  showDropdownChevron,
  ...tagProps
}: DoppelTagWithDropdownProps) {
  const formattedTag = showDropdownChevron ? (
    <>
      {tag}{' '}
      <Icon
        as={ChevronDownIcon}
        bg={DOPPEL_INTERNAL_PURPLE}
        borderRadius={4}
        boxSize={4}
        ml={1}
      />
    </>
  ) : (
    tag
  )
  return (
    <Box>
      <Dropdown
        customMenuButton={
          <MenuButton>
            <DoppelTag tag={formattedTag} {...tagProps} />
          </MenuButton>
        }
        items={dropdownItems}
        onSelect={onDropdownItemSelect}
      />
    </Box>
  )
}

type DoppelTagWithTooltipProps = {
  tag: React.ReactNode
  tooltip?: string
  tooltipPlacement?: 'auto' | 'top' | 'right' | 'bottom' | 'left'
  tooltipDisabled?: boolean
} & DoppelTagProps
export function DoppelTagWithTooltip({
  tooltip,
  tooltipPlacement,
  tooltipDisabled,
  ...rest
}: DoppelTagWithTooltipProps) {
  if (!tooltip) return <DoppelTag {...rest} />
  return (
    <Tooltip
      hasArrow
      isDisabled={tooltipDisabled ?? false}
      label={tooltip}
      placement={tooltipPlacement ?? 'bottom'}
    >
      <span>
        <DoppelTag {...rest} />
      </span>
    </Tooltip>
  )
}

type SeverityTagProps = {
  severity: Severity
  setSeverity?: (severity: Severity) => void // will render a dropdown
} & Partial<DoppelTagWithDropdownProps>
const SEVERITY_COLOR_MAP: { [key in Severity]: string } = {
  [Severity.LOW]: DOPPEL_DIM_YELLOW,
  [Severity.MEDIUM]: DOPPEL_FIREWALL_ORANGE,
  [Severity.HIGH]: DOPPEL_BREACH_RED,
}
export function SeverityTag({ severity, setSeverity, ...rest }: SeverityTagProps) {
  if (!severity) return null
  const formatText = (severity) =>
    `${unSnakeCase(SEVERITY_TO_LABEL[severity])} severity`
  const tagProps: DoppelTagProps = {
    tag: formatText(severity),
    bgColor: SEVERITY_COLOR_MAP[severity],
    color: DOPPEL_TEXT_WHITE,
  }
  if (!setSeverity) {
    return <DoppelTag {...tagProps} {...rest} />
  }
  return (
    <DoppelTagWithDropdown
      dropdownItems={Object.keys(SEVERITY_COLOR_MAP).map((key: Severity) => ({
        label: formatText(key),
        value: key,
      }))}
      onDropdownItemSelect={(value) => {
        setSeverity(value as Severity)
      }}
      {...tagProps}
      {...rest}
    />
  )
}

type ScoreTagProps = {
  score: number
} & Partial<DoppelTagProps>
const SCORE_COLOR_THRESHOLDS: Array<[number, string]> = [
  [80, DOPPEL_BREACH_RED],
  [50, DOPPEL_FIREWALL_ORANGE],
  [0, DOPPEL_DIM_YELLOW],
]
export function ScoreTag({ score, ...rest }: ScoreTagProps) {
  const computedScore = score ?? 0
  const textColor = DOPPEL_TEXT_WHITE
  const text = computedScore.toFixed(1)
  const [, tagColor] =
    SCORE_COLOR_THRESHOLDS.find(([threshold]) => computedScore >= threshold) ||
    SCORE_COLOR_THRESHOLDS[SCORE_COLOR_THRESHOLDS.length - 1]
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}

type ClassificationTagProps = {
  classification: ReportClassification
  setClassification?: (classification: ReportClassification) => void // will render a dropdown
} & Partial<DoppelTagWithDropdownProps>
export function ClassificationTag({
  classification,
  setClassification,
  ...rest
}: ClassificationTagProps) {
  if (!classification) return null

  const formatText = (classification: ReportClassification) =>
    unSnakeCase(classification) // will need external label mapping if exists
  const tagProps: DoppelTagProps = {
    tag: formatText(classification),
    bgColor:
      classification == ReportClassification.ACTIVE
        ? DOPPEL_ACTIVE_SILVER
        : DOPPEL_INACTIVE_GREY,
    color: DOPPEL_TEXT_WHITE,
  }
  if (!setClassification) {
    return <DoppelTag {...tagProps} {...rest} />
  }
  return (
    <DoppelTagWithDropdown
      dropdownItems={[
        ReportClassification.ACTIVE,
        ReportClassification.DOWN,
        ReportClassification.PARKED,
      ].map((key: ReportClassification) => ({
        label: formatText(key),
        value: key,
      }))}
      onDropdownItemSelect={(value) => {
        setClassification(value as ReportClassification)
      }}
      {...tagProps}
      {...rest}
    />
  )
}

type EnforcementStatusTagProps = {
  status: string
} & Partial<DoppelTagProps>
const ENFORCEMENT_STATUS_COLOR_MAP = {
  [EnforcementRequestStatus.STAGED]: DOPPEL_ACTIVE_SILVER,
  [EnforcementRequestStatus.REPORTED]: DOPPEL_CLOUD_BLUE,
  [EnforcementRequestStatus.BLOCKED]: DOPPEL_FIREWALL_ORANGE,
  [EnforcementRequestStatus.APPROVED]: DOPPEL_ENCRYPT_GREEN_SHADE,
  [EnforcementRequestStatus.CANCELED]: DOPPEL_INACTIVE_GREY,
  [EnforcementRequestStatus.REJECTED]: DOPPEL_BREACH_RED,
  [EnforcementRequestStatus.RETRACTION_SENT]: DOPPEL_ACTIVE_SILVER,
  [EnforcementRequestStatus.RETRACTED]: DOPPEL_INACTIVE_GREY,
  defaultColor: DOPPEL_INACTIVE_GREY,
}
export function EnforcementStatusTag({ status, ...rest }: EnforcementStatusTagProps) {
  if (!status) return null
  const textColor = DOPPEL_TEXT_WHITE
  const tagColor =
    ENFORCEMENT_STATUS_COLOR_MAP[status] || ENFORCEMENT_STATUS_COLOR_MAP.defaultColor
  const text = unSnakeCase(status)
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}

type InternalAssigneeTagProps = {
  internalAssignee: { id: string; email: string }
  setInternalAssignee?: (internalAssigneeId: string) => void
} & Partial<DoppelTagWithDropdownProps>
export function InternalAssigneeTag({
  internalAssignee,
  setInternalAssignee,
  ...rest
}: InternalAssigneeTagProps) {
  const [userId] = useUserID()
  const tagProps: DoppelTagProps = {
    tag: internalAssignee.email,
    bgColor: DOPPEL_INTERNAL_PURPLE,
    color: DOPPEL_WHITE,
  }

  const { data: assigneeData } = useGetUsersQuery({
    variables: {
      usersWhere: { is_internal: { _eq: true } },
    },
  })

  const alertAssignees = assigneeData?.users || []

  const sortedAssignees = getSortedAlertAssigneesWithUnassigned(alertAssignees, userId)

  return (
    <DoppelTagWithDropdown
      dropdownItems={sortedAssignees.map((key) => ({
        label: key.email,
        value: key.id,
      }))}
      onDropdownItemSelect={(value) => {
        setInternalAssignee(value)
      }}
      {...tagProps}
      {...rest}
    />
  )
}
