import { DOPPEL_BUTTON_GREY, FONT_SIZE_MEDIUM, DOPPEL_TEXT_WHITE } from '@/utils/style'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Flex, MenuButton, Spacer } from '@chakra-ui/react'
import { forwardRef, ReactNode } from 'react'

type DropdownButtonProps = {
  icon?: ReactNode
  label: ReactNode
  useMenuButton?: boolean
}

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ icon, label, useMenuButton = false, ...props }, ref) => {
    const ButtonComponent = useMenuButton ? MenuButton : Button
    return (
      <ButtonComponent
        {...(useMenuButton ? { as: Button } : {})}
        bg={DOPPEL_BUTTON_GREY}
        display="flex"
        fontSize={FONT_SIZE_MEDIUM}
        height={9}
        pl={3}
        pr={2}
        ref={ref}
        rightIcon={<ChevronDownIcon h={5} w={5} />}
        textColor={DOPPEL_TEXT_WHITE}
        {...props}
      >
        <Flex alignItems="center">
          {icon && (
            <>
              {icon}

              <Spacer mx={1} />
            </>
          )}

          {label}
        </Flex>
      </ButtonComponent>
    )
  },
)

DropdownButton.displayName = 'DropdownButton'

export default DropdownButton
