import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from '@chakra-ui/react'
import {
  BUTTON_COLOR_PRIMARY,
  DOPPEL_DARK_SECONDARY,
  DOPPEL_TEXT_WHITE,
  DOPPEL_BLANK_SHADE,
  BUTTON_COLOR_PRIMARY_SHADE,
  DOPPEL_BREACH_RED_SHADE,
  DOPPEL_BREACH_RED,
} from '@/utils/style'
import { useState, Children, cloneElement, useEffect, ReactNode } from 'react'

type DoppelModalProps = {
  isOpen: boolean
  onClose: () => void
  title: ReactNode
  primaryLabel?: string
  body: JSX.Element
  primaryAction?: (() => void) | (() => Promise<void>)
  isDanger?: boolean
  isAsync?: boolean
  size?: string
  isDisabled?: boolean
}

const DoppelModal: React.FC<DoppelModalProps> = ({
  isOpen,
  onClose,
  title,
  primaryLabel,
  body,
  primaryAction,
  isDanger = false,
  isAsync = false,
  size,
  isDisabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const buttonColor = isDanger ? DOPPEL_BREACH_RED : BUTTON_COLOR_PRIMARY
  const buttonColorHover = isDanger
    ? DOPPEL_BREACH_RED_SHADE
    : BUTTON_COLOR_PRIMARY_SHADE
  const onClickAction = isAsync
    ? async () => {
        setIsLoading(true)
        await primaryAction()
        setIsLoading(false)
      }
    : primaryAction

  // Reset loading state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false)
    }
  })

  const modalAutoSizeProps = {
    // for if no size is specified
    minW: '400px',
    w: 'fit-content',
    maxW: '75vw',
    h: 'fit-content',
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />

      <ModalContent bg={DOPPEL_DARK_SECONDARY} {...(size ? {} : modalAutoSizeProps)}>
        <ModalHeader
          fontSize={18}
          fontWeight={700}
          pb={2}
          textColor={DOPPEL_TEXT_WHITE}
        >
          {title}
        </ModalHeader>

        <ModalBody textColor={DOPPEL_TEXT_WHITE}>
          {Children.map(body, (child) => cloneElement(child, { disabled: isLoading }))}
        </ModalBody>

        {primaryAction && (
          <ModalFooter display={'flex'} w="full">
            <Box
              as={Button}
              bgColor={DOPPEL_BLANK_SHADE}
              disabled={isLoading}
              display={'flex'}
              flex={0.5}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Box>

            <Box
              _disabled={{ _hover: { bgColor: buttonColorHover } }}
              _hover={{ bgColor: buttonColorHover }}
              as={Button}
              bgColor={buttonColor}
              disabled={isLoading || isDisabled}
              display={'flex'}
              flex={0.5}
              onClick={onClickAction}
            >
              {isLoading ? <Spinner /> : primaryLabel}
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default DoppelModal
